/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import { USBGrid, USBColumn } from '@usb-shield/components-react';
import BannerWarning from '../common/banner'
// import Router from 'next/router'

import { useHistory } from 'react-router-dom';
import GlobalHead from '../organisms/GlobalHead';
import GlobalHeader from '../organisms/GlobalHeader';
import GlobalFooter from '../organisms/GlobalFooter';
import { endpoints } from '../../../config/connections';
import useSessionMaintainer from '../Hooks/useSessionMaintainer';

const Layout = (props) => {
  let history = useHistory();
  let bgClass = 'content-frame';
  if (props.bgclass) {
    bgClass = props.bgclass;
  }

  const {currentToken,getCurrentExtendTime,onSuccessChallenge,extendSessionSuccess,onIdleTimeoutLogoutSuccess,onIdleTimeoutLogoutFailure} = useSessionMaintainer()

  const authenticationPlugins = [
    '../../plugins/transmit/xmsdk.js',
    '../../plugins/transmit/xmui.js',
  ];

  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

  const urlCheck = () => {
    if (currentUrl.includes('/ManageUser')) {
      return <USBColumn largeSpan={16}>{props.children}</USBColumn>;
    }
    if (currentUrl.includes('/MyProfile')) {
      // return (<USBColumn largeSpan="16">
      return props.children;
      // </USBColumn>)
    }
    return <USBColumn largeSpan={12}>{props.children}</USBColumn>;
  };

  return (
    <>
      <GlobalHead title="Secure Document Portal" />
    
      <GlobalHeader
        currentToken={currentToken}
        refreshTimeCount={getCurrentExtendTime()}
        authenticationPlugins={authenticationPlugins}
        extendSessionSuccess={extendSessionSuccess}
        onSuccessChallenge={onSuccessChallenge}
        onIdleTimeoutLogoutSuccess={onIdleTimeoutLogoutSuccess}
        onIdleTimeoutLogoutFailure={onIdleTimeoutLogoutFailure}
      />
     <BannerWarning></BannerWarning>
      <USBGrid addClasses={bgClass}>
        <USBColumn largeSpan={2} />
        {/* {urlCheck()} */}
        <USBColumn largeSpan={12}>{props.children}</USBColumn>
      </USBGrid>
      <GlobalFooter />
    </>
  );
};

export default Layout;

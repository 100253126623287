import React, { useState, useEffect, useContext, createContext } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import rateLimit from 'axios-rate-limit';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { FeatureFlagsContext } from '../../components/Hooks/useFeatureFlags';
import { endpoints } from '../../../config/connections';

import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import Loader from '../../components/utilities/Loader';
import { USBGrid, USBColumn, USBButton, USBModal, USBModalHeader, USBModalBody, USBModalFooter, USBModalCloseIcon, USBSelect, } from '@usb-shield/components-react';
import { DocContext } from '../context/DocContext';
import AutoCompleteDropdown from '../../components/utilities/AutoCompleteDropdown';
import PaginatedTable from '../../components/utilities/PaginatedTable';
import USBDatePicker from '@usb-shield/react-date-picker';

import searchIcon from '../../../assets/img/search.png';

const docContext = createContext(DocContext);

const CBPortfolioParent = () => {

    const defaultDatePickerErrorMessages = {
        default: 'Please enter a valid date.',
        201: 'Please enter a valid date.',
        202: 'Date should be in the past.',
        203: 'Date should be in the future.',
        221: 'Enter no more than 10 characters.',
        213: 'Enter numbers only.',
        224: '(Optional field)',
        228: "That date isn't available. Please try again.",
    }
    const { featureDetails } = useContext(FeatureFlagsContext);
    const defaultCalendarSettings = { occurrence: 'both', areBankHolidaysDisabled: false, areWeekendsDisabled: false, numberOfYearsToShow: '10' }

    const defaultPortfolioFilters = {
        parentCompany: '',
        businessUnit: '',
        dealName: '',
        dealId: '',
        documentType: '',
        status: 'Submitted',
        following: '',
        closingBinderDate: {
            dateInput: {
                initValue: '', labelText: 'Closing Binder Date', minimumAge: 0,
                maximumAge: 10,
            }, calendar: { ...defaultCalendarSettings, occurrence: 'both' },
        },
        receivedDate: {
            dateInput: {
                initValue: '', inputName: 'Received Date', minimumAge: 0,
                maximumAge: 10,
            }, calendar: { ...defaultCalendarSettings, occurrence: 'past' },
        },
        sortBy: ''
    }
    const autoDropDownDefaultList = {
        original: [{ content: 'Select', value: '' }],
        filtered: [{ content: 'Select', value: '' }]
    }

    const defaultSortByList = [
        { content: 'Select', value: '' },
        { content: 'Due Soon', value: 'dueSoon' },
        { content: 'Due Later', value: 'dueLater' },
        { content: 'Parent Company (A-Z)', value: 'parentCompanyAsc' },
        { content: 'Parent Company (Z-A)', value: 'parentCompanyDesc' },
        { content: 'Deal Name (A-Z)', value: 'dealNameAsc' },
        { content: 'Deal Name (Z-A)', value: 'dealNameDesc' },
        { content: 'Deal Id (0-9)', value: 'dealIdAsc' },
        { content: 'Deal Id (9-0)', value: 'dealIdDesc' },
        { content: 'Document Type (A-Z)', value: 'docTypeAsc' },
        { content: 'Document Type (Z-A)', value: 'docTypeDesc' },
    ]

    const history = useHistory();

    const [loading, setLoading] = useState(false)
    const [clearFilterFlag, setClearFilterFlag] = useState(false);
    const [employeeFlag, setEmployeeFlag] = useState(false);
    const [cbPortfolioData, setCBPortfolioData] = useState([]);
    const [filteredCBPortfolioData, setFilteredCBPortfolioData] = useState([]);
    const [portfolioFilters, setPortfolioFilters] = useState({ ...defaultPortfolioFilters });
    const [parentCompanyList, setParentCompanyList] = useState({ ...autoDropDownDefaultList });
    const [businessUnitList, setBusinessUnitList] = useState({ ...autoDropDownDefaultList });
    const [dealNameList, setDealNameList] = useState({ ...autoDropDownDefaultList });
    const [dealIdList, setDealIdList] = useState({ ...autoDropDownDefaultList });
    const [documentTypeList, setDocumentTypeList] = useState([{ content: 'Select', value: '' }]);
    const [statusList, setStatusList] = useState([
        { content: 'Select', value: '' },
        { content: 'Not Submitted', value: 'Not Submitted' },
        { content: 'Submitted', value: 'Submitted' },
        { content: 'Under Review', value: 'Under Review' },
        { content: 'Needs Action', value: 'Needs Action' },
        { content: 'Completed', value: 'Completed' }
    ]);
    const [followingList, setFollowingList] = useState([
        { content: 'Select', value: '' },
        { content: 'Yes', value: 'true' },
        { content: 'No', value: 'false' }
    ]);
    const [sortByList, setSortByList] = useState(defaultSortByList);
    const { servicingUserRole, setCurrentMenu } = useContext(DocContext);
    const [currentPageTemp, setCurrentPageTemp] = useState(1);
    const [fileCountIds,setFileCountIds] = useState('')
    const [fileCountData,setFileCountData] = useState(null)
    // State variables to handle toggle
    const defaultToggleValue = { parentCompany: false, businessUnit: false, dealName: false, dealId: false }
    const [isToggleOpen, setIsToggleOpen] = useState({ ...defaultToggleValue })

    const filterDropdownDefault = [{ content: 'Select', value: '', },];

    const isUniqueAndNotEmpty = (list, value) => {
        if (list.filter((e) => e.value === value).length === 0 && value !== '' || null || undefined) {
            return true;
        } else {
            return false;
        }
    }

    const isNonEmptyNullUndefined = (value) => {
        if (value !== '' && value !== null && value !== undefined) {
            return true;
        } else {
            return false;
        }
    }

    const sortFunction = (a, b) => {
        if (a.value < b.value) {
            return -1;
        }
        if (a.value > b.value) {
            return 1;
        }
        return 0;
    }

    const getClosingBinderDocuments = async (docStatus) => {
        setLoading(true)
        const https_logged_user = rateLimit(
            axios.create({
                headers: {
                    Accept: 'application/json',
                    'Correlation-ID': `${sessionStorage.getItem(
                        'loggedUser'
                    )} ${sessionStorage.getItem('correlationId')}`,
                    'Client-Application': 'CDC',
                    Authorization: `Bearer ${sessionStorage.getItem('at')}`,
                    'jwt': `${sessionStorage.getItem('tok')}`,
                    'user-id': sessionStorage.getItem('loggedUser') ? sessionStorage.getItem('loggedUser') : `${sessionStorage.getItem('assignedUser')}`
                },
            }),
            { maxRequests: 7, perMilliseconds: 1000, maxRPS: 7 }
        );
        let docIds = [];
        let userID = sessionStorage.getItem('loggedUser');
        let userrole = sessionStorage.getItem('userrole');

        if (userID && userrole === 'employee') {
            const res = await https_logged_user
                .get(
                    `${endpoints.apigee}/document-management/cdc/requirements/v1/requirements-subscribe/user/userId`

                )

            sessionStorage.setItem('maxCount', res.data.maxCount);
            sessionStorage.setItem('followingCount', res.data.documentIds.length);
            docIds = res?.data?.documentIds ? [...res?.data?.documentIds] : []

        }
        let portfolioData = []
        const url = `${endpoints.apigee}/document-management/cdc/requirements/v1/employee/userId/documents?callType=before&startDay=-9999&endDay=9999&status=${docStatus}&isClosingBinder=true&recordsCount=0`

        const https = rateLimit(
            axios.create({
                headers: {
                    Accept: 'application/json',
                    'Correlation-ID': `${sessionStorage.getItem(
                        'loggedUser'
                    )} ${sessionStorage.getItem('correlationId')}`,
                    'Client-Application': 'CDC',
                    Authorization: `Bearer ${sessionStorage.getItem('at')}`,
                    'jwt': `${sessionStorage.getItem('tok')}`,
                    'user-id': sessionStorage.getItem('remote_user') ? JSON.parse(sessionStorage.getItem('remote_user')).userID : `${sessionStorage.getItem('assignedUser')}`
                },
            }),
            { maxRequests: 7, perMilliseconds: 1000, maxRPS: 7 }
        );
        const response = await https.get(url)
        if (response?.data?.docs?.length > 0) {
            portfolioData = response.data.docs;
            portfolioData.forEach((o) => {
                if (docIds.includes(o.id)) {
                    o.following = 'true';
                } else {
                    o.following = 'false';
                }
            });
            
                setCBPortfolioData(portfolioData)
                loadFilterS(portfolioData, "original")
            
            setLoading(false)
            setClearFilterFlag(false)
            return portfolioData
        } else {
            setFilteredCBPortfolioData([])
            setClearFilterFlag(false)
            setLoading(false)
            return []
        }

    }
    const getClosingBinderDocumentsFileCount = async () => {
        const url = `${endpoints.apigee}/document-management/cdc/requirements/v1/getCBFileCountsForRequirementIds?requirements=${fileCountIds}`
        const https = rateLimit(
            axios.create({
                headers: {
                    Accept: 'application/json',
                    'Correlation-ID': `${sessionStorage.getItem(
                        'loggedUser'
                    )} ${sessionStorage.getItem('correlationId')}`,
                    'Client-Application': 'CDC',
                    Authorization: `Bearer ${sessionStorage.getItem('at')}`,
                    'jwt': `${sessionStorage.getItem('tok')}`,
                },
            }),
            { maxRequests: 7, perMilliseconds: 1000, maxRPS: 7 }
        );
        const response = await https.get(url)
        setFileCountData(response.data)
    }

    useEffect(()=>{
        if(fileCountIds !== ''){
            getClosingBinderDocumentsFileCount()
        }
    },[fileCountIds])

    const createDropdownListFromResults = (data) => {
        const parentCompanyData = [...filterDropdownDefault];
        const businessUnitData = [...filterDropdownDefault];
        const dealNameData = [...filterDropdownDefault];
        const dealIdData = [...filterDropdownDefault]
        const documentTypeData = [...filterDropdownDefault]; // Doctype

        data.forEach((value) => {
            if (isUniqueAndNotEmpty(parentCompanyData, value.metadata.parentCompanyName)) {
                parentCompanyData.push({ content: value.metadata.parentCompanyName + '', value: value.metadata.parentCompanyName + '', });
            }
            if (isUniqueAndNotEmpty(businessUnitData, value.docType)) {
                businessUnitData.push({ content: value.docType, value: value.docType, });
            }
            if (isUniqueAndNotEmpty(dealNameData, value.metadata.dealName)) {
                dealNameData.push({ content: value.metadata.dealName, value: value.metadata.dealName, });
            }
            if (isUniqueAndNotEmpty(dealIdData, value.metadata.dealId)) {
                dealIdData.push({ content: value.metadata.dealId, value: value.metadata.dealId, });
            }
            if (isUniqueAndNotEmpty(documentTypeData, value.requirementName)) {
                documentTypeData.push({ content: value.requirementName, value: value.requirementName, });
            }
        })
        businessUnitData.sort(sortFunction);
        parentCompanyData.sort(sortFunction);
        dealNameData.sort(sortFunction);
        dealIdData.sort(sortFunction);
        documentTypeData.sort(sortFunction);
        return { "parentCompanyData": parentCompanyData, "businessUnitData": businessUnitData, "dealNameData": dealNameData, "dealIdData": dealIdData, "documentTypeData": documentTypeData }
    }

    const loadFilterS = (results, indicator) => {
        let dataLists = createDropdownListFromResults(results)
        if (indicator == "original") {
            setParentCompanyList({ original: dataLists.parentCompanyData, filtered: dataLists.parentCompanyData });
            setBusinessUnitList({ original: dataLists.businessUnitData, filtered: dataLists.businessUnitData })
            setDealNameList({ original: dataLists.dealNameData, filtered: dataLists.dealNameData })
            setDealIdList({ original: dataLists.dealIdData, filtered: dataLists.dealIdData })
            setDocumentTypeList(dataLists.documentTypeData)
        } else if (indicator == "filtered") {
            setDealNameList({ ...dealNameList, filtered: dataLists.dealNameData })
            setDealIdList({ ...dealIdList, filtered: dataLists.dealIdData })
        }
    };

    const filterPortfolioData = (filters, fullData) => {
        let filterValues = []
        let filterKeys = []
        let filteredData = []
        Object.entries(filters).forEach(([key, value]) => {
            if (typeof value == typeof {}) {
                if (isNonEmptyNullUndefined(value.dateInput.initValue)) {
                    filterValues.push(value.dateInput.initValue)
                    filterKeys.push(key)
                }
            } else if (isNonEmptyNullUndefined(value) && typeof value != typeof {}
            ) {
                filterValues.push(value)
                filterKeys.push(key)
            }
        })
        let fullDataList = fullData;
        if (filterValues.length > 0) {
            filteredData = fullDataList.filter((item) =>
                (filterKeys.includes('parentCompany') ? filterValues.includes(item.metadata.parentCompanyName) || (item.metadata.parentCompanyName + '').toLocaleLowerCase().includes(filters.parentCompany.toLocaleLowerCase()) : true) &&
                (filterKeys.includes('businessUnit') ? filterValues.includes(item.docType) || (item.docType + '').toLocaleLowerCase().includes(filters.businessUnit.toLocaleLowerCase()) : true) &&
                (filterKeys.includes('dealName') ? filterValues.includes(item.metadata.dealName) || (item.metadata.dealName + '').toLocaleLowerCase().includes(filters.dealName.toLocaleLowerCase()) : true) &&
                (filterKeys.includes('dealId') ? filterValues.includes(item.metadata.dealId) || (item.metadata.dealId + '').toLocaleLowerCase().includes(filters.dealId.toLocaleLowerCase()) : true) &&
                (filterKeys.includes('documentType') ? filterValues.includes(item.requirementName) : true) &&
                (filterKeys.includes('status') ? filterValues.includes(item.status) : true) &&
                (filterKeys.includes('following') ? filterValues.includes(item.following) : true) &&
                (filterKeys.includes('closingBinderDate') ? filterValues.includes(moment.utc(item?.closingBinderDate).format('MM/DD/YYYY')) : true) &&
                (filterKeys.includes('receivedDate') ? filterValues.includes(moment(item.submittedDate).format('MM/DD/YYYY')) : true)
            )
            setFilteredCBPortfolioData(filteredData)
        } else {
            setFilteredCBPortfolioData(fullData)
        }
    }

    const onStatusChange = async (docStatus) => {
        let filters = { ...portfolioFilters, status: docStatus }
        let data = await getClosingBinderDocuments(docStatus)
        setLoading(true)
        if (docStatus == "Completed" && data != undefined) {
            setPortfolioFilters({ ...portfolioFilters, status: docStatus,following:'' })
            filterPortfolioData({ ...portfolioFilters, status: docStatus,following:'' }, data)
        } else if (docStatus == "" && data != undefined) {
            filterPortfolioData(filters, data)
            setPortfolioFilters(filters)
        } else if (docStatus != "" && docStatus != "Completed" && data != undefined) {
            setPortfolioFilters({ ...portfolioFilters, status: docStatus,following:'' })
            filterPortfolioData({ ...portfolioFilters, status: docStatus,following:'' }, data)
            setCBPortfolioData(data)
            loadFilterS(data, "original")
        }
        setLoading(false)
    }

    const onDropDownToggleClick = (columnName) => {
        if (columnName == "parentCompany") {
            isToggleOpen.parentCompany ? setIsToggleOpen({ ...defaultToggleValue, parentCompany: false }) : setIsToggleOpen({ ...defaultToggleValue, parentCompany: true })
            setParentCompanyList({ ...parentCompanyList, filtered: parentCompanyList.original })
        } else if (columnName == "businessUnit") {
            isToggleOpen.businessUnit ? setIsToggleOpen({ ...defaultToggleValue, businessUnit: false }) : setIsToggleOpen({ ...defaultToggleValue, businessUnit: true })
            setBusinessUnitList({ ...businessUnitList, filtered: businessUnitList.original })
        } else if (columnName == "dealName") {
            isToggleOpen.dealName ? setIsToggleOpen({ ...defaultToggleValue, dealName: false }) : setIsToggleOpen({ ...defaultToggleValue, dealName: true })
        } else if (columnName == "dealId") {
            isToggleOpen.dealId ? setIsToggleOpen({ ...defaultToggleValue, dealId: false }) : setIsToggleOpen({ ...defaultToggleValue, dealId: true })
        }
    }

    const onDropDownSearchChange = (value, filterName) => {
        let tempList = []
        let filters;
        if (filterName == "parentCompanyInput") {
            setIsToggleOpen({ ...defaultToggleValue, parentCompany: true })
            tempList = parentCompanyList.original.filter((item) => item.value.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
            setParentCompanyList({ ...parentCompanyList, filtered: tempList })
            filters = { ...portfolioFilters, parentCompany: value }
        } else if (filterName == "businessUnitInput") {
            setIsToggleOpen({ ...defaultToggleValue, businessUnit: true })
            tempList = businessUnitList.original.filter((item) => item.value.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
            setBusinessUnitList({ ...businessUnitList, filtered: tempList })
            filters = { ...portfolioFilters, businessUnit: value }
        } else if (filterName == "dealNameInput") {
            setIsToggleOpen({ ...defaultToggleValue, dealName: true })
            tempList = dealNameList.filtered.filter((item) => item.value.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
            setDealNameList({ ...dealNameList, filtered: tempList })
            filters = { ...portfolioFilters, dealName: value }
        } else if (filterName == "dealIdInput") {
            setIsToggleOpen({ ...defaultToggleValue, dealId: true })
            tempList = dealIdList.filtered.filter((item) => item.value.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
            setDealIdList({ ...dealIdList, filtered: tempList })
            filters = { ...portfolioFilters, dealId: value }
        }
        filterPortfolioData(filters, cbPortfolioData);
        setPortfolioFilters(filters)
    }

    const onDropDownItemClick = (value, column) => {
        setIsToggleOpen({ ...defaultToggleValue })
        let filters;
        if (column == "parentCompanyInput") {
            filters = { ...portfolioFilters, parentCompany: value + '', businessUnit: '', dealName: '', dealId: '' }
        } else if (column == "businessUnitInput") {
            filters = { ...portfolioFilters, businessUnit: value }
        } else if (column == "dealNameInput") {
            filters = { ...portfolioFilters, dealName: value }
        } else if (column == "dealIdInput") {
            filters = { ...portfolioFilters, dealId: value }
        }
        setPortfolioFilters(filters)
        filterPortfolioData(filters, cbPortfolioData)
    }

    const onClearFilterClick = async () => {
        setClearFilterFlag(true)
        setPortfolioFilters({ ...defaultPortfolioFilters })
        let status = sessionStorage.getItem('userrole') === 'employee' ? 'Submitted' : 'Not Submitted'
        let data = await getClosingBinderDocuments(status)
        if (data != undefined) {
            filterPortfolioData(defaultPortfolioFilters, data)
        }
    }

    const sortFilteredData = (key) => {
        let temp = filteredCBPortfolioData;
        switch (key) {
            case 'dueSoon':
                temp.sort((a, b) => {
                    if (new Date(a.dueDate) > new Date(b.dueDate)) { return 1; }
                    else if (new Date(a.dueDate) < new Date(b.dueDate)) { return -1; }
                    return 0;
                })
                break;
            case 'dueLater':
                temp.sort((a, b) => {
                    if (new Date(a.dueDate) > new Date(b.dueDate)) { return -1; }
                    else if (new Date(a.dueDate) < new Date(b.dueDate)) { return 1; }
                    return 0;
                })
                break;
            case 'parentCompanyAsc':
                temp.sort((a, b) => {
                    if (a.metadata.parentCompanyName > b.metadata.parentCompanyName) { return 1; }
                    else if (a.metadata.parentCompanyName < b.metadata.parentCompanyName) { return -1; }
                    return 0;
                })
                break;
            case 'parentCompanyDesc':
                temp.sort((a, b) => {
                    if (a.metadata.parentCompanyName > b.metadata.parentCompanyName) { return -1; }
                    else if (a.metadata.parentCompanyName < b.metadata.parentCompanyName) { return 1; }
                    return 0;
                })
                break;
            case 'dealNameAsc':
                temp.sort((a, b) => {
                    if (a.metadata.dealName > b.metadata.dealName) { return 1; }
                    else if (a.metadata.dealName < b.metadata.dealName) { return -1; }
                    return 0;
                })
                break;
            case 'dealNameDesc':
                temp.sort((a, b) => {
                    if (a.metadata.dealName > b.metadata.dealName) { return -1; }
                    else if (a.metadata.dealName < b.metadata.dealName) { return 1; }
                    return 0;
                })
                break;
            case 'dealIdAsc':
                temp.sort((a, b) => {
                    if (a.metadata.dealId > b.metadata.dealId) { return 1; }
                    else if (a.metadata.dealId < b.metadata.dealId) { return -1; }
                    return 0;
                })
                break;
            case 'dealIdDesc':
                temp.sort((a, b) => {
                    if (a.metadata.dealId > b.metadata.dealId) { return -1; }
                    else if (a.metadata.dealId < b.metadata.dealId) { return 1; }
                    return 0;
                })
                break;
            case 'docTypeAsc':
                temp.sort((a, b) => {
                    if (a.requirementName > b.requirementName) { return 1; }
                    else if (a.requirementName < b.requirementName) { return -1; }
                    return 0;
                })
                break;
            case 'docTypeDesc':
                temp.sort((a, b) => {
                    if (a.requirementName > b.requirementName) { return -1; }
                    else if (a.requirementName < b.requirementName) { return 1; }
                    return 0;
                })
                break;
            case 'receivedAsc':
                temp.sort((a, b) => {
                    if (new Date(a.submittedDate) > new Date(b.submittedDate)) { return 1; }
                    else if (new Date(a.submittedDate) < new Date(b.submittedDate)) { return -1; }
                    return 0;
                })
                break;
            case 'receivedDesc':
                temp.sort((a, b) => {
                    if (new Date(a.submittedDate) > new Date(b.submittedDate)) { return -1; }
                    else if (new Date(a.submittedDate) < new Date(b.submittedDate)) { return 1; }
                    return 0;
                })
                break;
            default:
                temp.sort((a, b) => {
                    if (new Date(a.dueDate) > new Date(b.dueDate)) {
                        return 1;
                    } else if (new Date(a.dueDate) < new Date(b.dueDate)) {
                        return -1;
                    }
                    return 0;
                })
                break;
        }
        setFilteredCBPortfolioData(temp)
    }

    const closeOpenMenus = (event) => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(event.target)) {
            if (event.srcElement.className !== 'down-image' && event.srcElement.className !== 'dropdown-each' &&
                event.srcElement.nodeName != 'LABEL' &&
                event.srcElement.nodeName != 'INPUT'
            ) {
                setIsToggleOpen({ ...defaultToggleValue })
            }
        }
    }

    const onEscapeClick = (event, item) => {
        if (event.keyCode === 27) {
            setIsToggleOpen({ ...defaultToggleValue })
        }
    };

    const initialSetup = async () => {        
        let status = '';
        let filters;
        if (sessionStorage.getItem('portfolioFilters')) {
            filters = JSON.parse(sessionStorage.getItem('portfolioFilters'))
            setPortfolioFilters(filters)
            status = JSON.parse(sessionStorage.getItem('portfolioFilters')).status
        } else if (sessionStorage.getItem('userrole') === 'employee') {
            status = 'Submitted';
          } else {
            status = 'Not Submitted';
          }
        let data;
        if (status != "Completed") {
            data = await getClosingBinderDocuments(status)
        } else {
            data = await getClosingBinderDocuments('Completed')
        }
        if (data != undefined) {
            if (filters != undefined) {
                filterPortfolioData(filters, data)
            } else {
                filterPortfolioData(defaultPortfolioFilters, data)
            }
        }
    }

    useEffect(() => {
        document.addEventListener('click', closeOpenMenus, true);
        if (sessionStorage.getItem('userrole') === 'employee') {
            setEmployeeFlag(true)
        } else {
            setEmployeeFlag(false)
        }
        initialSetup()
    }, [featureDetails])

    useEffect(() => {
        if (portfolioFilters.status != "Not Submitted") {
            setSortByList([...defaultSortByList,
            { content: 'Older Submissions', value: 'receivedAsc' },
            { content: 'Recent Submissions', value: 'receivedDesc' }, ,])
        }
        sessionStorage.setItem('portfolioFilters', JSON.stringify(portfolioFilters))
    }, [portfolioFilters])


    return (
        <>
            <USBGrid addClasses="content-frame">


            <USBColumn largeSpan={16}>
          {sessionStorage.getItem('filesToCopy') ? (
            <div className="warning-panel-remote">
              Please select the requirement to paste the {(sessionStorage.getItem('filesToCopy')?.split(",")?.length)} selected documents.{' '}

              To cancel copying, click &nbsp;    

                            <USBButton
                                size="small"
                                variant='secondary'
                                handleClick={
                                    () => { sessionStorage.removeItem('filesToCopy'); sessionStorage.removeItem('pastedRequirementId'); sessionStorage.removeItem('pasted'); window.location.reload(); }
                                }
                                ariaLabel='Cancel Copy'
                            >
                                Cancel
                            </USBButton>

             
            </div>
          ) : (
            ''
          )}
        </USBColumn>

        


                <USBColumn largeSpan={16}>

                    {/* Breadcrumb start */}

                    <USBColumn largeSpan={8}>
                        <USBBreadcrumb
                            id="cbPortfolio"
                            text="CB Dashboard"
                            breadcrumbs={[
                                { id: 1, text: 'CB Portfolio', href: '/ClosingBinders/Portfolio' },
                            ]}
                        />
                    </USBColumn>

                    {/* Breadcrumb end - page content start*/}

                    <USBGrid addClasses="content-frame content-bg">
                        {loading ? <Loader /> : null}

       


                        <USBColumn largeSpan={16}>
                            <h1 className="heading-h1">
                                Closing Binders Portfolio
                            </h1>
                            <div className="filter-by">Filter by:</div>

                            <div className="sorting-inputs">

                                {(employeeFlag ||
                                    servicingUserRole === 'vendorsiteadmin' ||
                                    servicingUserRole === 'vendorcontributor' ||
                                    servicingUserRole === 'unlistedvendor') && !loading &&
                                    !JSON.parse(sessionStorage.getItem('remote_user')) ? (
                                    <AutoCompleteDropdown
                                        id="parentCompanyInput"
                                        label='Parent Company'
                                        placeholder="Search by parent company"
                                        inputValue={portfolioFilters.parentCompany}
                                        itemList={parentCompanyList.filtered}
                                        isDropdownOpen={isToggleOpen.parentCompany}
                                        dropDownToggle={() => onDropDownToggleClick("parentCompany")}
                                        onInputChange={onDropDownSearchChange}
                                        onItemClick={onDropDownItemClick}
                                        onKeyPress={onEscapeClick}
                                    />
                                ) : (
                                    ''
                                )}

                                {!loading && <AutoCompleteDropdown
                                    id="businessUnitInput"
                                    label='Business Unit'
                                    placeholder="Search by business unit"
                                    inputValue={portfolioFilters.businessUnit}
                                    itemList={businessUnitList.filtered}
                                    isDropdownOpen={isToggleOpen.businessUnit}
                                    dropDownToggle={() => onDropDownToggleClick("businessUnit")}
                                    onInputChange={onDropDownSearchChange}
                                    onItemClick={onDropDownItemClick}
                                    onKeyPress={onEscapeClick}
                                />}

                                {!loading && <AutoCompleteDropdown
                                    id="dealNameInput"
                                    label='Deal Name'
                                    placeholder="Search by deal name"
                                    inputValue={portfolioFilters.dealName}
                                    itemList={dealNameList.filtered}
                                    isDropdownOpen={isToggleOpen.dealName}
                                    dropDownToggle={() => onDropDownToggleClick("dealName")}
                                    onInputChange={onDropDownSearchChange}
                                    onItemClick={onDropDownItemClick}
                                    onKeyPress={onEscapeClick}
                                />}

                                {!loading && <AutoCompleteDropdown
                                    id="dealIdInput"
                                    label='Deal Id'
                                    placeholder="Search by Deal Id"
                                    inputValue={portfolioFilters.dealId}
                                    itemList={dealIdList.filtered}
                                    isDropdownOpen={isToggleOpen.dealId}
                                    dropDownToggle={() => onDropDownToggleClick("dealId")}
                                    onInputChange={onDropDownSearchChange}
                                    onItemClick={onDropDownItemClick}
                                    onKeyPress={onEscapeClick}
                                />}

                                {!clearFilterFlag && <div className="filterDropdowns">
                                    <USBSelect
                                        id="documenttype"
                                        name="documenttype"
                                        initValue={portfolioFilters.documentType}
                                        labelText="Document type"
                                        optionsArray={documentTypeList}
                                        handleChange={(e) => {
                                            let filters = { ...portfolioFilters, documentType: e?.target?.value }
                                            setPortfolioFilters(filters)
                                            filterPortfolioData(filters, cbPortfolioData)
                                        }}
                                    />
                                </div>}

                                {!clearFilterFlag && !loading  && <div className="filterDropdowns">
                                    <USBSelect
                                        id="status"
                                        name="status"
                                        initValue={portfolioFilters.status}
                                        labelText="Status"
                                        optionsArray={statusList}
                                        handleChange={(e) => {
                                            onStatusChange(e?.target?.value)
                                        }}
                                    />
                                </div>}

                                {!clearFilterFlag && !loading && <div className="filterDropdowns">
                                    <USBSelect
                                        id="follow"
                                        name="follow"
                                        initValue={portfolioFilters.following}
                                        labelText="Following"
                                        optionsArray={followingList}
                                        handleChange={(e) => {
                                            let filters = { ...portfolioFilters, following: e?.target?.value }
                                            setPortfolioFilters(filters)
                                            filterPortfolioData(filters, cbPortfolioData)
                                        }}
                                    />
                                </div>}

                                {employeeFlag ||
                                    servicingUserRole === 'vendorsiteadmin' ||
                                    servicingUserRole === 'vendorcontributor' ||
                                    servicingUserRole === 'unlistedvendor' ||
                                    servicingUserRole === 'siteadmin' ||
                                    servicingUserRole === 'contributor' ||
                                    servicingUserRole === 'customer' ? (
                                    <>

                                        <div className="datefilterDropdownsCB">
                                            {!clearFilterFlag && !loading && <USBDatePicker
                                                dynamicInputValue={portfolioFilters.closingBinderDate.dateInput.initValue}
                                                statusUpdateCallback={(e) => {
                                                    
                                                    if ((e.event == "onChange" || e.event == "dynamicValue") && (e.errorCode == 0 || e.errorCode == 224 || e.calendarKey=="date cell clicked")) {
                                                        // if (e?.inputValue?.length == 10) {
                                                            let filters = {
                                                                ...portfolioFilters, closingBinderDate: {
                                                                    dateInput: {
                                                                        initValue: e.inputValue, labelText: 'Closing Binder Date', minimumAge: 0,
                                                                        maximumAge: 10,
                                                                    }, calendar: { ...defaultCalendarSettings, occurrence: 'both' },
                                                                }
                                                            }
                                                            setPortfolioFilters(filters)
                                                            filterPortfolioData(filters, cbPortfolioData)
                                                        }
                                                    // }
                                                }}
                                                content={{
                                                    dateInput: {
                                                        labelText: "Closing Binder Date",
                                                        errorMessages: { ...defaultDatePickerErrorMessages }
                                                    },
                                                }}
                                                settings={portfolioFilters?.closingBinderDate}
                                            />
                                            }
                                        </div>

                                        <div className="datefilterDropdownsCB">
                                            {!clearFilterFlag && !loading && portfolioFilters.status !== 'Not Submitted' && <USBDatePicker
                                                dynamicInputValue={portfolioFilters.receivedDate.dateInput.initValue}
                                                statusUpdateCallback={(e) => {
                                                   
                                                    if ((e.event == "onChange" || e.event == "dynamicValue") && (e.errorCode == 0 || e.errorCode == 224 || e.calendarKey=="date cell clicked")) {
                                                        
              
                                                            let filters = {
                                                                ...portfolioFilters, receivedDate: {
                                                                    dateInput: {
                                                                        initValue: e.inputValue, labelText: 'Received Date', minimumAge: 0,
                                                                        maximumAge: 10,
                                                                    }, calendar: { ...defaultCalendarSettings, occurrence: 'past' }
                                                                }
                                                            }
                                                            setPortfolioFilters(filters)
                                                            filterPortfolioData(filters, cbPortfolioData)
                                                        }
                                                }}
                                                content={{
                                                    dateInput: {
                                                        labelText: "Received Date",
                                                        errorMessages: { ...defaultDatePickerErrorMessages }
                                                    },
                                                }}
                                                settings={portfolioFilters.receivedDate}
                                            />
                                            }
                                        </div>
                                    </>
                                ) : (
                                    ''
                                )}

                            </div>

                            <div className="clearFilter">
                                <USBButton
                                    id="clearfilter"
                                    name="clearfilter"
                                    variant="utility"
                                    className="clear-filter"
                                    handleClick={onClearFilterClick}>
                                    Clear filters
                                </USBButton>
                            </div>

                            {!clearFilterFlag && <div className="sort-inline">
                                <div className='sortByText'> Sort By</div>
                                <div className="sortByDropdown">
                                    <USBSelect
                                        id="sortBy"
                                        name="sortBy"
                                        initValue={portfolioFilters.sortBy}
                                        optionsArray={sortByList}
                                        labelText=""
                                        handleChange={(e) => sortFilteredData(e?.target?.value)}
                                    />
                                </div>
                            </div>}

                            <div className="totalresultsno">
                                {filteredCBPortfolioData.length}
                                &nbsp; results
                            </div>
                            {!loading && <div className="page-content">
                                {filteredCBPortfolioData && filteredCBPortfolioData.length > 0 ?
                                    <PaginatedTable
                                        fullTableData={filteredCBPortfolioData}
                                        statusValue={portfolioFilters.status}
                                        getCBDocs={getClosingBinderDocuments}
                                        filterData={filterPortfolioData}
                                        filters = {portfolioFilters}
                                        currentPageTemp={currentPageTemp}
                                        setCurrentPageTemp={setCurrentPageTemp}
                                        features={featureDetails}
                                        setFileCountIds={setFileCountIds}
                                        fileCountData={fileCountData}
                                    /> :
                                    <PaginatedTable
                                        fullTableData={[]}
                                        statusValue={portfolioFilters.status}
                                        getCBDocs={getClosingBinderDocuments}
                                        filterData={filterPortfolioData}
                                        filters = {portfolioFilters}
                                        currentPageTemp={currentPageTemp}
                                        setCurrentPageTemp={setCurrentPageTemp}
                                        features={featureDetails}
                                        setFileCountIds={setFileCountIds}
                                        fileCountData={fileCountData}
                                    />

                                }
                            </div>}
                        </USBColumn>
                    </USBGrid>
                </USBColumn>
            </USBGrid>
        </>
    )
}

export default CBPortfolioParent;
import { useState, useEffect } from 'react';
import { parseJwt } from '../utilities/Utils';

export default function useSessionMaintainer() {
  const token =
    typeof sessionStorage !== 'undefined'
      ? sessionStorage.getItem('tok')
      : null;

  const [currentToken, setCurrentToken] = useState(token);
  const getCurrentExtendTime = () => {
    const currentIat =
      typeof sessionStorage !== 'undefined' ? checkIfSessionExists() : null;
    const sessionTimer = 600000;
    let refreshTime = currentIat * 1000 + sessionTimer - Date.now();
    if (refreshTime <= 60000) {
      refreshTime = 60000;
    }
    return refreshTime;
  };

  const onSuccessChallenge = (response) => {
    console.log(response?.type);
    if (response && response?.type === 'logout') {
      if (sessionStorage.getItem('userrole') !== 'employee') {
        // sessionStorage.clear();
        setCurrentToken(null);
        const userDataTmp = JSON.parse(sessionStorage.getItem('userData'));
        sessionStorage.clear();
        sessionStorage.setItem('userData', JSON.stringify(userDataTmp));
        window.location.href = '/';
      } else {
        const userDataTmp = JSON.parse(sessionStorage.getItem('userData'));
        sessionStorage.clear();
        sessionStorage.setItem('userData', JSON.stringify(userDataTmp));
        setCurrentToken(null);
        window.location.href = '/EmployeeRedirect?tp=sto';
      }
    } else {
      sessionStorage.setItem('tok', null);
      sessionStorage.setItem('tok', response.token);
      localStorage.setItem('tok', response.token);
      sessionStorage.setItem('at', null);
      sessionStorage.setItem(
        'at',
        parseJwt(sessionStorage.getItem('tok')).custom_data.at
      );
      sessionStorage.setItem(
        'currentSession',
        sessionStorage.getItem('currentSession')
      );
      setCurrentToken(response.token);

      // const element = document.getElementById('manageuser-app');

      // if (element) {
      //     const content = element.contentWindow;
      //     content.postMessage(
      //         { type: 'token', data: response.token },
      //         endpoints.manageUser
      //     );
      // }
    }
  };

  const extendSessionSuccess = (response) => {
    sessionStorage.setItem('tok', null);
    sessionStorage.setItem('tok', response.token);
    localStorage.setItem('tok', response.token);
    sessionStorage.setItem('at', null);
    sessionStorage.setItem(
      'at',
      parseJwt(sessionStorage.getItem('tok')).custom_data.at
    );
    sessionStorage.setItem(
      'currentSession',
      sessionStorage.getItem('currentSession')
    );
    setCurrentToken(response.token);

    // const element = document.getElementById('manageuser-app');

    // if (element) {
    //     const content = element.contentWindow;
    //     content.postMessage(
    //         { type: 'token', data: response.token },
    //         endpoints.manageUser
    //     );
    // }
  };

  const onIdleTimeoutLogoutSuccess = () => {
    window.onbeforeunload = null;
    if (sessionStorage.getItem('userrole') !== 'employee') {
      sessionStorage.clear();
      setCurrentToken(null);
      window.location.href = '/';
    } else {
      // sessionStorage.clear();
      setCurrentToken(null);
      window.location.href = '/EmployeeRedirect?tp=sto';
    }
  };

  const onIdleTimeoutLogoutFailure = () => {
    window.onbeforeunload = null;
    if (sessionStorage.getItem('userrole') !== 'employee') {
      sessionStorage.clear();
      setCurrentToken(null);
      window.location.href = '/';
    } else {
      // sessionStorage.clear();
      setCurrentToken(null);
      window.location.href = '/EmployeeRedirect?tp=sto';
    }
  };

  const checkIfSessionExists = () => {
    let parsedToken;
    if (sessionStorage.getItem('tok') !== null) {
      parsedToken = parseJwt(sessionStorage.getItem('tok')).iat;
    } else {
      parsedToken = null;
    }
    return parsedToken;
  };

  useEffect(() => {
    setCurrentToken(token);
  }, []);

  return {currentToken,getCurrentExtendTime,onSuccessChallenge,extendSessionSuccess,onIdleTimeoutLogoutSuccess,onIdleTimeoutLogoutFailure}
}

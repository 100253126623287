import { downloadFile } from './utils';
import React, { useEffect, useState, useMemo } from 'react';
// @ts-ignore
import { USBIconDownload, USBIconShow } from '@usb-shield/react-icons';

// @ts-ignore
import { USBTooltip } from '@usb-shield/components-react';

export const metadataDetails = [
  {
    title: 'Deal Name',
    key: 'metadata.dealName',
  },
  {
    title: 'Deal Number',
    key: 'metadata.dealId',
  },
  {
    title: 'Legal Entity',
    key: 'metadata.reportingEntity',
  },
];

export const columns = [
  {
    label: 'Document Title',
    accessor: 'documentMetadata.documentName',
    sortable: true,
    style: { width: '60%' },
    cell: (row) => {
      const metadata = row.documentMetadata?.[0] || {};
      return (
        <>
          {metadata.documentName}
          <br />
          {metadata.fileNetMetadata?.fileSize}
        </>
      );
    },
  },
  {
    label: 'Uploaded Date',
    accessor: 'documentMetadata.uploadDate',
    sortable: true,
    style: { width: '20%' },
  },
  {
    label: '',
    accessor: '',

    cell: (row) => {
      const metadata = row.documentMetadata?.[0] || {};
      const enablePreview = metadata.documentName?.includes('.pdf');
      const preview = (id)=>{
        let type = 'IB';
        var url = window.location.pathname;
        var reqId = url.substring(url.lastIndexOf('/') + 1);
        window.open(
          `/document-viewer?url=` + id+ `&type=` + type + `&req=` + reqId
        );
      }
      return (
        <>
          <button
            className="table-action-icon"
            onClick={async () => {
              await downloadFile({
                metadata: row.documentMetadata?.[0] || {},
              });
            }}
           >
            <USBIconDownload
              colorVariant={'brand-primary'}
              title={`Download file`}
              ariaHidden={true}
              id="add-icon"
              focusable={true}
              tabindex={-1}
            />
          </button>
          <button
            className="table-action-icon"
            onClick={() => {
              console.log('clicked preview');
              if(enablePreview){
                preview(row?.documentMetadata[0]?.universalMetadataId?row.documentMetadata[0].universalMetadataId:row.documentMetadata[0].filenetId);
            }
              else{
                alert("Only .pdf documents can be previewed.");
              }
            }}
            disabled={false}
          >
            <USBIconShow
              colorVariant={enablePreview ? 'brand-primary' : 'grey60'}
              title={`Preview file`}
              ariaHidden={true}
              id="add-icon"
              focusable={true}
              tabindex={-1}
              size={24}
              addClasses="action-preview-icon"
            />
          </button>
        </>
      );
    },
    style: { width: '15%', textAlign: 'right' },
  },
];
